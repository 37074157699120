import * as React from "react";

import SEO from "../../components/seo";

export default function GetInTouchPage() {
  return (
    <>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <SEO title="Get in touch" />
      <h1>Get in touch</h1>

      <p>Feel free to drop me a line below...</p>

      <form
        id="form1"
        name="form1"
        autoComplete="off"
        encType="multipart/form-data"
        method="post"
        action="https://remybach.wufoo.com/forms/z7x3p3/#public"
      >
        <ul>
          <li id="foli3" className="notranslate">
            <label id="title3" htmlFor="Field3">
              Your name
              <span id="req_3" className="req">
                *
              </span>
            </label>
            <input
              id="Field3"
              name="Field3"
              type="text"
              className="medium"
              defaultValue=""
              maxLength="255"
              required
            />
          </li>
          <li id="foli4" className="notranslate">
            <label id="title4" htmlFor="Field4">
              Your email
              <span id="req_4" className="req">
                *
              </span>
            </label>
            <input
              id="Field4"
              name="Field4"
              type="email"
              spellCheck="false"
              className="large"
              defaultValue=""
              maxLength="255"
              required
            />
          </li>
          <li id="foli5" className="notranslate">
            <label id="title5" htmlFor="Field5">
              What are you contacting me about?
              <span id="req_5" className="req">
                *
              </span>
            </label>
            <input
              id="Field5"
              name="Field5"
              type="text"
              className="large"
              defaultValue=""
              maxLength="255"
              required
            />
          </li>
          <li id="foli6" className="notranslate">
            <label id="title6" htmlFor="Field6">
              Your message
              <span id="req_6" className="req">
                *
              </span>
            </label>
            <textarea
              id="Field6"
              name="Field6"
              className="large"
              spellCheck="true"
              rows="10"
              cols="50"
              required
            ></textarea>
          </li>
          <li className="buttons">
            <input
              id="saveForm"
              name="saveForm"
              className="btTxt submit"
              type="submit"
              value="Submit"
            />
          </li>
          <li className="visuallyhidden">
            <label htmlFor="comment">Do Not Fill htmlFor=""ThFillis Out</label>
            <textarea name="comment" id="comment" rows="1" cols="1"></textarea>
            <input
              type="hidden"
              id="idstamp"
              name="idstamp"
              value="Gi5MxU7z0dDdyU8UXy46yvV0ZL07GCwPHZ5CtWCKDQc="
            />
          </li>
        </ul>
      </form>
    </>
  );
}
